import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  Login, ForgotPassword, SecurityCode,
  AdminOrders, AdminInvestorPortfolio, Profile,
  AdminInvestors, NotFound,
  ResetPassword, AdminOpportunities, AddOpportunity, ViewOpportunity,
  AdminCompanyList, AdminCompanyAddEdit, AdminOrderDetails, EditProfile, SignupSuccess, PasswordSuccess, ForgotPasswordSuccess
} from './Pages';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Login} />
        <Route exact path='/signup-success' component={SignupSuccess} />
        <Route exact path='/password-success' component={PasswordSuccess} />
        <Route path='/forgot-password-success' component={ForgotPasswordSuccess} />
        <Route path='/forgot' component={ForgotPassword} />
        <Route path="/resetPassword:id" component={ResetPassword} />
        <Route path="/createPassword:email" component={ResetPassword} />
        <Route path="/security" component={SecurityCode} />
        <Route path="/profile" component={Profile} />
        <Route path="/edit-profile" component={EditProfile} />
        {/* admin */}
        <Route path="/admin-orders" component={AdminOrders} />
        <Route path="/admin-orders-details" component={AdminOrderDetails} />
        <Route path="/admin-investor-portfolio" component={AdminInvestorPortfolio} />
        <Route path="/admin-opportunities" component={AdminOpportunities} />
        <Route path="/investors" component={AdminInvestors} />
        <Route path="/add-opportunity" component={AddOpportunity} />
        <Route path="/view-opportunity" component={ViewOpportunity} />
        <Route path="/admin-company-list" component={AdminCompanyList} />
        <Route path="/admin-company-add-edit" component={AdminCompanyAddEdit} />
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

//Exporting app
export default App; 
