export const Constants = {

   //baseURL:"http://localhost:80/api",
   //baseURL:"https://api1.spolisetti.com/api",
   baseURL:"https://prodapi3.topshelfequitypartners.com/api",
   personaTemplateId: "itmpl_uJMLSGXjxyS264wsF6PFBTSa",
   personaEnvironment: "sandbox",
  reCaptchaKey: "6LeImwggAAAAAC8hZ721a0Isw0O-nqVLc4aG7oZ7",
  anvilBaseUrl: "https://app.useanvil.com/form/topshelf-equity-partners/irs-w9/"
}
