export const Strings = {
    adminId: '2',
    successType: "success",
    errorType: "error",

    //error messages
    networkErrorMessage: "Network Error!",
    noOfOrdersPerPage: 10,
    noOfOffersPerPage: 12,

    //titles
    opportunityTitle: "Opportunity",
    loginTitle: "Login",
    signUpTitle: "Signup",
    myPortfolioTitle: "My Portfolio",
    profileTitle: "Profile",
    ViewOpportunityTitle: "View Opportunity",
    resetPasswordTitle: "ResetPassword",
    companyTitle: "Company",

    //button titles
    loginButtonTitle: "Continue",
    nextButtonTitle: "Sign in",
    signUpButtonTitle: "Signup",
    forgotPasswordTitle: "Forgot password?",
    sendOtpButtonTitle: "CREATE ACCOUNT",
    signInTitle: "Click here to login",
    saveButtonTitle: "Save",
    expressIntrestTitle: "Express Interest",
    addButtonTitle: "Add",

    //login
    loginSuccessMessage: "Logged in Successfully",
    investorLoginError:  'There is no Admin for this account',

    //sign up
    passwordTitle: "Password",
    passwordPlaceholder: "Password",
    passwordInputMessage: "Please Enter Password",
    passwordMinLength: "Password should be minimum 8 characters",
    passwordMaxLength: "Password should be maximum 16 characters",

    otpTitle: "Otp",
    otpPlaceholder: "Otp",
    otpInputMessage: "Please Enter Otp",
    otpMinLength: "Otp should be 6 characters",

    countryCodeList: ["+91", "+1", "+44"],
    countrysList: ["United States of America", "India", "UAE"],
    countrysNubesr: [
        { code: "+1", length: 10 },
        { code: "+91", length: 10 },
        { code: "+971", length: 11 }
    ],

    signUpSuccessMessage: 'A verification link has been sent to your mail, Please check inbox!',
    //payments
    firstNameTitle: "First Name",
    firstNamePlaceholder: "First Name",
    firstNameInputMessage: 'Please Enter First Name',
    firstNameInputMinLengthMessage: "First Name should be minimum 3 characters",
    firstNameInputMaxLengthMessage: "First Name should be minimum 30 characters",

    middleNameTitle: "Middle Name",
    middleNamePlaceholder: "Middle Name",
    middleNameInputMessage: 'Please Enter Middle Name',
    middleNameInputMinLengthMessage: "Middle Name should be minimum 3 characters",
    middleNameInputMaxLengthMessage: "Middle Name should be minimum 30 characters",

    entityName: "Entity Name",
    entityInputMessage: 'Please Enter Entity Name',
    entityNameInputMinLengthMessage: 'Entity Name should be minimum 3 characters',
    entityNameInputMaxLengthMessage: 'Entity Name should be minimum 30 characters',
    entityNamePlaceholder: '  entity name',

    judicialName: "Judicial",
    judicialInputMessage: 'Please Enter Judicial',
    judicialPlaceholder: 'Judicial',


    lastNameTitle: "Last Name",
    lastNamePlaceholder: "Last name",
    lastNameInputMessage: 'Please Enter Last Name',
    lastNameInputMinLengthMessage: "Last Name should be minimum 1 characters",
    lastNameInputMaxLengthMessage: "Last Name should be maximum 30 characters",

    fullNameInputMessage: 'Please Enter Full Name',
    fullNameInputMinLengthMessage: "Full Name should be minimum 1 characters",
    fullNameInputMaxLengthMessage: "Full Name should be maximum 30 characters",
    fullNamePlaceholder: "Full Name",

    emailTitle: "Email",
    emailPlaceholder: "Email",
    emailInputMessage: "Please Enter Email",
    invalidEmailMessage: "Email is invalid",


    amountTitle: "Amount",
    amountPlaceholder: "Amount",
    amountInputMessage: "Please Enter Amount",
    amountInputMinLengthMessage: "Amount must contain 1 digit",

    sourceTitle: "Description",
    sourcePlaceholder: "Description",
    sourceInputMessage: "Please Enter Description",

    dateTitle: "Date",
    dateInputMessage: "Please Enter Date",

    phoneNumberTitle: "Contact Number :",
    phoneNumberPlaceholder: "Contact Number",
    phoneNumberInputMessage: "Please Enter Contact Number",
    phoneNumberInputMinLengthMessage: "Contact Number must contain 10 digits",
    phoneNumberInputMaxLengthMessage: "Contact Number must contain 15 digits",

    addressTitle: "Address",
    addressPlaceholder: "Address",
    addressInputMessage: 'Please Enter Address',
    addressInputMinLengthMessage: "Address should be minimum 3 characters",
    addressInputMaxLengthMessage: "Address should be minimum 30 characters",

    registeredAddressTitle: "Registered Address",
    registeredAddressPlaceholder: "Registered Address",
    registeredAddressInputMessage: 'Please Enter Registered Address',
    registeredAddressInputMinLengthMessage: "Registered Address should be minimum 3 characters",
    registeredAddressInputMaxLengthMessage: "Registered Address should be minimum 30 characters",

    einTitle: "EIN",
    einPlaceholder: "Ein",
    einInputMessage: 'Please Enter EIN',
    einInputMinLengthMessage: "EIN should be minimum 3 characters",
    einInputMaxLengthMessage: "EIN should be minimum 30 characters",

    entityNameTitle: "Entity Name",
    entityNamePlaceholder: "Entity Name",
    entityNameInputMessage: 'Please Enter Entity Name',
    entityNameInputMinLengthMessage: "Entity Name should be minimum 3 characters",
    entityNameInputMaxLengthMessage: "Entity Name should be minimum 30 characters",

    regEntityNameTitle: "Registered Entity",
    regEntityNamePlaceholder: "Registered Entity",
    regEntityNameInputMessage: 'Please Enter Registered Entity',
    regEntityNameInputMinLengthMessage: "Registered Entity should be minimum 3 characters",
    regEntityNameInputMaxLengthMessage: "Registered Entity should be minimum 30 characters",

    iraAgentNameTitle: "IRA Agent Name",
    iraAgentNamePlaceholder: "Ira Agent Name",
    iraAgentNameInputMessage: 'Please Enter Ira Agent Name',
    iraAgentNameInputMinLengthMessage: "Ira agent name should be minimum 3 characters",
    iraAgentNameInputMaxLengthMessage: "Ira agent name should be minimum 30 characters",

    iraEmailTitle: "IRA Representative Agent Email",
    iraEmailPlaceholder: "Ira representative email",
    iraEmailInputMessage: "Please Enter Ira representative email",

    countryCodePlaceholder: "+1",
    countryCodeInputMessage: "Please choose country code",

    onlyNumbersMessage: "Contains only numbers",

    //profile
    profileNotVerificationError: "Please wait profile verification is in INPROGRESS",
    countries: ['usa', 'uk', 'india'],
    genders: ['male', 'female', 'others'],
    state: ['State one', 'State two'],
    city: ['City One', 'City Two'],
    investorExperiences: ['1', '2', '3'],
    TaxIdTitle: "Tax Identification Number",
    TaxId: "Please Enter Tax Identification Number",
    taxIdPlaceholder: "Tax Identification Number",
    taxIdMinLengthMessage: "Tax id should be 9 characters",
    fullName: "Full Name",
    stateTitle: "State or Province",
    stateInputMessage: "Please Enter State or Province",
    statePlaceholder: "Select State or Province",
    streetTitle: "Street One",
    streetTitleTwo: "Street Two",
    streetInputMessage: "Please Enter Address",
    streetPlaceholder: "Address",
    cityTitle: "City",
    cityInputMessage: "Please Enter city Name",
    cityPlaceholder: "Please Select City Name",
    zipTitle: "Postal/Zip Code",
    zipInputMessage: "Please Enter Zip Code",
    zipPlaceholder: " Zip Code",


    countryTitle: "Country",
    countryPlaceholder: "Choose your country",
    countryInputMessage: 'Please Enter Country',

    relationTitle: "Relationdhip",
    relationInputMessage: "Please Enter Relationdhip",
    relationPlaceholder: "Please Select Relationdhip",

    genderTitle: "Gender",
    genderPlaceholder: "Gender",
    genderInputMessage: 'Please Enter Gender',

    accreditedInvestorTitle: "Accredited Investor",
    accreditedInvestorPlaceholder: "Accredited Investor",
    accreditedInvestorInputMessage: 'Please Enter Accredited Investor ',
    accreditedInvestorInputMinLengthMessage: "Accredited investor should be minimum 3 characters",
    accreditedInvestorInputMaxLengthMessage: "Accredited investor should be minimum 30 characters",

    investmentExperienceTitle: "Investment Experience",
    investmentExperiencePlaceholder: "Investment Experience",
    investmentExperienceInputMessage: 'Please Enter Investment Experience',

    uploadDrivingLicenseTitle: "Upload Driving License",
    uploadDrivingLicensePlaceholder: "Please Upload Driving License ",

    uploadw9Title: "Upload Tax Form",
    uploadw9InputMessage: "Tax Form",
    uploadw9Title: "Upload Tax Form",
    //user types admin or inverstor
    userTypesCode: [
        1, //admin
        2   //inverstor user
    ],

    //verification code will be sent to email or phone number
    authType: [
        1, //email
        2, // phone number 
    ],

    //admin investors
    investorsTitle: "Investors",
    noDataMessage: "No Data",
    noOfItemsPerPage: 10,

    //opportunities
    notSelectedSharesError: "Please select shares to buy",

    //forgot password
    otpSentSuccessfully: "Successfully sent otp to email.",

    documentTypesList: [
        "Subscription Agreement",
        "Tax Documents",
        "Capital Statements",
        "Side Letters",
        "W9(or)W8(or)W8-BEN-E"
    ],

    relationships: [
        "Parent",
        "Sibling",
        "Spouse",
        "Child",
        "Others"
    ],
    adminRoutes: [
        '/profile',
        '/admin-orders',
        '/admin-investor-portfolio',
        '/admin-opportunities',
        '/investors',
    ],

    investorRoutes: [
        '/profile',
        '/investor-opportunities',
        // '/invester-portfolio',
        '/my-holdings',
        '/investor-individual',
        '/deal-investment',
        '/document-permissions'
    ],
    ExpressInterstStatusTypes: [
        { id: 1, status: "Initiated" },
        { id: 2, status: "Inprogress" },
        { id: 3, status: "Completed" },
        { id: 4, status: "Cancelled" },
        { id: 5, status: "Exited" },
    ],



    myHoldingsStatus: [
        { id: 1, status: "Indicated Interest" },
        { id: 2, status: "Subscription Received" },
        { id: 3, status: "Member Signed" },
        { id: 4, status: "Funded" },
        { id: 4, status: "Counter Signed" },
        { id: 4, status: "Subscription Completed" },

    ],

    DocusignStatusTypes: [
        { id: 1, status: "Completed (or) final Admin signed" },
        { id: 2, status: "Declined (or) final Admin declined" },
        { id: 3, status: "Person 1 signed" },
        { id: 4, status: "person 1 declined" },
        { id: 5, status: "Person 2 signed" },
        { id: 6, status: "person 2 declined" },
        { id: 7, status: "Person 3 signed" },
        { id: 8, status: "person 3 declined" },
        { id: 9, status: "Person 4 signed" },
        { id: 10, status: "person 4 declined" },
    ],

    DocusignStatusTypes: [
        { id: 1, status: "Completed (or) final Admin signed" },
        { id: 2, status: "Declined (or) final Admin declined" },
        { id: 3, status: "Person 1 signed" },
        { id: 4, status: "person 1 declined" },
        { id: 5, status: "Person 2 signed" },
        { id: 6, status: "person 2 declined" },
        { id: 7, status: "Person 3 signed" },
        { id: 8, status: "person 3 declined" },
        { id: 9, status: "Person 4 signed" },
        { id: 10, status: "person 4 declined" },
    ],

    //add opportunities
    companyNameTitle: "Company Name",
    companyNamePlaceholder: "Company Name",
    companyNameInputMessage: "Please Enter Company Name",

    anvilTemplateIdTitle: "Anvil TemplateId",
    anvilTemplateIdPlaceholder: "Enter Anvil TemplateId",
    anvilTemplateIdInputMessage: "Please Enter Anvil TemplateId",

    fundTitle: "Fund Name",
    fundPlaceholder: "Enter Fund Name",
    fundInputMessage: "Please Enter Fund Name",

    branchUrlNameTitle: "Branch Url Name",
    branchUrlNamePlaceholder: "Enter Branch Url Name",
    branchUrlNameInputMessage: "Please Enter Branch Url Name",

    minimumInvestmentTitle: "Minimum Investment",
    minimumInvestmentPlaceholder: "Enter Minimum Investment",
    minimumInvestmentInputMessage: 'Please Enter Minimum Investment',

    carriedInterestTitle: "Carried Interest",
    carriedInterestPlaceholder: "Enter Carried Interest",
    carriedInterestInputMessage: 'Please Enter Carried Interest',


    lastRoundTitle: "Last Round",
    lastRoundPlaceholder: "Last Round",
    lastRoundInputMessage: 'Please Enter Last Round',
    lastRoundInputMinLengthMessage: "Last Round should be minimum 1 characters",
    lastRoundInputMaxLengthMessage: "Last Round should be minimum 30 characters",

    lastRoundVTitle: "Last Round Valuation (mil $)",
    lastRoundVPlaceholder: " Last Round Valuation ",
    lastRoundVInputMessage: 'Please Enter Last Round ',
    lastRoundVInputMinLengthMessage: "Last Round Valuation  should be minimum 3 characters",
    lastRoundVInputMaxLengthMessage: "Last Round Valuation  should be minimum 30 characters",

    numberOfSharesTitle: "Number Of Shares",
    numberOfSharesPlaceholder: "Number Of Shares",
    numberOfSharesInputMessage: 'Please Enter Number Of Shares',
    numberOfSharesInputMinLengthMessage: "Number Of Shares should be minimum 3 characters",
    numberOfSharesInputMaxLengthMessage: "Number Of Shares should be minimum 30 characters",

    displayOrderTitle: "Display Order",
    displayOrderPlaceholder: "Display Order",
    displayOrderInputMessage: 'Please Enter Display Order',
    displayOrderInputMinLengthMessage: "Display Order should be minimum 1 characters",
    displayOrderInputMaxLengthMessage: "Display Order should be minimum 30 characters",

    pricePerShareTitle: "Price per Share",
    pricePerSharePlaceholder: "Price per Share",
    pricePerShareInputMessage: 'Please Enter Price per Share',
    pricePerShareInputMinLengthMessage: "Price per Share should be minimum 3 characters",
    pricePerShareInputMaxLengthMessage: "Price per Share should be minimum 30 characters",

    pricePerUnitTitle: "Price per Unit",
    pricePerUnitPlaceholder: "Price per Unit",
    pricePerUnitInputMessage: 'Please Enter Price per Unit',
    pricePerUnitInputMinLengthMessage: "Price per Unit should be minimum 3 characters",
    pricePerUnitInputMaxLengthMessage: "Price per Unit should be minimum 30 characters",


    dealImpliedValuationTitle: "Deal Implied Valuation (mil $)",
    dealImpliedValuationPlaceholder: "Deal Implied Valuation",
    dealImpliedValuationInputMessage: 'Please Enter Deal Implied Valuation',
    dealImpliedValuationInputMinLengthMessage: "Deal Implied Valuation should be minimum 3 characters",
    dealImpliedValuationInputMaxLengthMessage: "Deal Implied Valuation should be minimum 30 characters",

    offerPriceTitle: "Offer Price ($)",
    offerPricePlaceholder: "Offer Price",
    offerPriceInputMessage: 'Please Enter Offer Price',
    offerPriceInputMinLengthMessage: "Offer Price should be minimum 3 characters",
    offerPriceInputMaxLengthMessage: "Offer Price should be minimum 30 characters",

    securityTypeTitle: "Security Type",
    securityTypePlaceholder: "Enter choose Security Type",
    securityTypeInputMessage: 'Please Enter Security Type ',

    //add opportunity
    opportunityAddTitle: "Add opportunity",
    opportunityEditTitle: "Edit opportunity",

    //reset password
    newPasswordTitle: "New Password",
    newPasswordPlaceholder: "New Password",
    newPasswordInputMessage: 'Please Enter New Password',
    confirmPasswordTitle: "Confirm Password",
    confirmPasswordPlaceholder: "Confirm Password",
    confirmPasswordInputMessage: 'Please Enter Confirm Password',
    linkExpiredMessage: "Link expired!",
    passwordsNotMatchedErrorMessage: "The two passwords that you entered do not match!",

    uploadDrivingLicenseInputMessage: "Please upload your driving license",

    totalAllocationTitle: "Total Allocation",
    totalAllocationPlaceholder: "Total Allocation",
    totalAllocationInputMessage: 'Please Enter Total Allocation',
    totalAllocationInputMinLengthMessage: "Total Allocation should be minimum 1 characters",
    totalAllocationInputMaxLengthMessage: "Total Allocation should be minimum 30 characters",

    impliedValueTitle: "Implied Value",
    impliedValuePlaceholder: "Implied Value",
    impliedValueInputMessage: 'Please Enter Implied Value',
    impliedValueInputMinLengthMessage: "Implied Value should be minimum 1 characters",
    impliedValueInputMaxLengthMessage: "Implied Value should be minimum 30 characters",

    descriptionTitle: "Description",
    descriptionPlaceholder: "Description",
    descriptionInputMessage: 'Please Enter Description',
    descriptionInputMinLengthMessage: "Description should be minimum 1 characters",
    descriptionInputMaxLengthMessage: "Description should be minimum 300 characters",

    Countries: ["Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Brazil",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Côte d'Ivoire",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Comoros",
        "Congo (Congo-Brazzaville)",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czechia (Czech Republic)",
        "Democratic Republic of the Congo",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini (fmr.Swaziland)",
        "Ethiopia",
        "Fiji",
        "Finland",
        "France",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Greece",
        "Grenada",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Holy See",
        "Honduras",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Morocco",
        "Mozambique",
        "Myanmar (formerly Burma)",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "North Korea",
        "North Macedonia",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine State",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Qatar",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Korea",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Sweden",
        "Switzerland",
        "Syria",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States of America",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Zambia",
        "Zimbabwe"],

    States: {
        "India": ["Andhra Pradesh",
            "Arunachal Pradesh",
            "Assam",
            "Bihar",
            "Chhattisgarh",
            "Goa",
            "Gujarat",
            "Haryana",
            "Himachal Pradesh",
            "Jharkhand",
            "Karnataka",
            "Kerala",
            "Madhya Pradesh",
            "Maharashtra",
            "Manipur",
            "Meghalaya",
            "Mizoram",
            "Nagaland",
            "Odisha",
            "Punjab",
            "Rajasthan",
            "Sikkim",
            "Tamil Nadu",
            "Telangana",
            "Tripura",
            "Uttar Pradesh",
            "Uttarakhand",
            "West Bengal"],
        "United States of America": ["California",
            "Texas",
            "Florida",
            "New York",
            "Pennsylvania",
            "Illinois",
            "Ohio",
            "Georgia",
            "North Carolina",
            "Michigan",
            "New Jersey",
            "Virginia",
            "Washington",
            "Arizona",
            "Tennessee",
            "Massachusetts",
            "Indiana",
            "Missouri",
            "Maryland",
            "Colorado",
            "Wisconsin",
            "Minnesota",
            "South Carolina",
            "Alabama",
            "Louisiana",
            "Kentucky",
            "Oregon",
            "Oklahoma",
            "Connecticut",
            "Utah",
            "Nevada",
            "Iowa",
            "Arkansas",
            "Mississippi",
            "Kansas",
            "New Mexico",
            "Nebraska",
            "Idaho",
            "West Virginia",
            "Hawaii",
            "New Hampshire",
            "Maine",
            "Montana",
            "Rhode Island",
            "Delaware",
            "South Dakota",
            "North Dakota",
            "Alaska",
            "Vermont",
            "Wyoming"]
    },
    mailJoinSuccess: "Thank you for joining our mailing list!",

    transactionVolumeError: "Minimum shares quantity must be greater than 0",
    entityNotSelectedError: "Please Select Entity",
    iraNotSelectedError: "Please Select IRA",

    //admin company
    companyAddTitle: "Add company",
    companyEditTitle: "Edit company",

    portfolioCompanyNameTitle: "Portfolio Company Name",
    portfolioCompanyNamePlaceholder: "Enter your portfolio company name",
    portfolioCompanyNameInputMessage: 'Please Enter Company Name',
    portfolioCompanyNameInputMinLengthMessage: "Portfolio Company Name should be minimum 3 characters",
    portfolioCompanyNameInputMaxLengthMessage: "Portfolio Company Name should be minimum 30 characters",

    companyUrlTitle: "Company Url",
    companyUrlPlaceholder: "Enter Company Url ",
    companyUrlInputMessage: 'Please Enter Valid Company Url',

    companyLogoTitle: "Company Logo",
    companyLogoPlaceholder: "Please Upload Company Logo ",
    companyLogoInputMessage: 'Please Upload Company Logo',

    companyCategoryPlaceholder: "Category",
    companyLocationPlaceholder: "Location",
    companyCategoryPlaceholder: "Enter Category",
    companyLocationPlaceholder: "Enter Location",
    companyCategoryTitle: "Category",
    companyLocationTitle: "Location",


    companyPeopleTitle: "People Logo",
    companyPeoplePlaceholder: "Please Upload People Logo ",
    companyPeopleInputMessage: 'Please Upload People Logo',


    companyFinancialsTitle: "Financials Logo",
    companyFinancialsPlaceholder: "Please Upload Financials Logo ",
    companyFinancialsInputMessage: 'Please Upload Financials Logo',

    sectorTypes: [
        { id: 1, name: 'private' },
        { id: 2, name: 'government' }
    ],
    sectorTitle: "Sector",
    sectorPlaceholder: "Choose your sector ",
    sectorInputMessage: 'Please choose your sector!',

    introOneTitle: "Intro One",
    introOnePlaceholder: "Choose your intro one ",
    introOneInputMessage: 'Enter your intro one!',

    introTwoTitle: "Intro Two",
    introTwoPlaceholder: "Choose your intro two ",
    introTwoInputMessage: 'Enter your intro two!',

    foundedTitle: "Founded",
    foundedPlaceholder: "Choose your founded ",
    foundedInputMessage: 'Enter your founded!',

    fundingToDateTitle: "Funding To Date",
    fundingToDatePlaceholder: "Choose your funding to date ",
    fundingToDateInputMessage: 'Enter your funding to date!',

    overviewTitle: "Overview",
    overviewPlaceholder: "Enter Overview",
    overviewInputMessage: 'Please Enter Overview',

}
