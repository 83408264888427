import React, { Component } from 'react'
import { Row, Col, notification,  Breadcrumb } from 'antd';

import { AppHeader, Loading, Footer } from '../../Components';
import editIcon from '../../assets/images/editIcon.png';
import '../../App.css';
import './Profile.css';
import { connect } from 'react-redux';
import { Strings } from '../../Constants/Strings';
import { updateProfile, getUserData, getUserDataProfile, uploadImage, uploadSecureFiles, getSignedUrls } from '../../Redux/Crud';
import { Constants } from '../../Constants/Constants';




class Profile extends Component {
    formRef = React.createRef();
    formRef1 = React.createRef();

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            user: null,
            actualUser: null,
            profilePicture: null,
            hideHeader: false,
            disableCheckBox: false,
            checkBoxStatus: false,
            mailingFormDisable: false,
            showMailingSelect: false,
            showPrimarySelect: false,
            step: 0,
            taxIdentificationForm: null,
            documentsList: [],
            isShowModal: false,
            anvilUrl: null
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.location.state && this.props.location.state.hideHeader) {
            this.setState({ hideHeader: false })
        }
        if (this.props.location.state && this.props.location.state.step) {
            this.setState({ step: this.props.location.state.step })
        }
        this.getUserDetails()
    }

    //getting user data
    getUserDetails() {
        if (localStorage.getItem('userData')) {
            let user = JSON.parse(localStorage.getItem('userData'));
            this.props.getUserDataProfile(user.id)
                .then(res => {
                    if (res.type === Strings.successType) {
                        if (res.data && res.data.userType && res.data.userType === 1) {
                            this.setState({
                                profilePicture: res.data.profilePicture,
                                taxIdentificationForm: res.data.taxIdentificationForm,
                                actualUser: res.data
                            }, () => {
                                let url = Constants.anvilBaseUrl + (this.state.actualUser && this.state.actualUser.w9FormCode ? this.state.actualUser.w9FormCode : null) + "/";
                                console.log(url)
                                this.setState({ anvilUrl: url })
                            });
                            console.log(this.state.actualUser)
                            this.bindValues(res.data)
                        } else {
                            localStorage.clear();
                            this.props.history.push('/');
                        }
                    } else {
                        localStorage.clear();
                        this.props.history.push('/');
                    }
                })
        } else {
            localStorage.clear();
            this.props.history.push('/');
        }
    }

    
    //bind user data in form
    bindValues(user) {
        let obj = {
            firstName: user.firstName,
            middleName: user.middleName ? user.middleName : "",
            lastName: user.lastName,
            countryCode: user.countryCode,
            phoneNumber: user.phoneNumber,
            email: user.email,
            fullName: user.firstName + " " + (user.middleName ? user.middleName + " " : "") + user.lastName,
            taxId: user.taxId,
            primaryCity: user.primaryAddress ? user.primaryAddress.city : "",
            primaryCountry: user.primaryAddress ? user.primaryAddress.country : "",
            primaryState: user.primaryAddress ? user.primaryAddress.state : "",
            primaryStreetOne: user.primaryAddress ? user.primaryAddress.streetOne : "",
            primaryStreetTwo: user.primaryAddress ? user.primaryAddress.streetTwo : "",
            primaryZipcode: user.primaryAddress ? user.primaryAddress.zipcode : "",
            mailingCity: user.mailingAddress ? user.mailingAddress.city : "",
            mailingCountry: user.mailingAddress ? user.mailingAddress.country : "",
            mailingState: user.mailingAddress ? user.mailingAddress.state : "",
            mailingStreetOne: user.mailingAddress ? user.mailingAddress.streetOne : "",
            mailingStreetTwo: user.mailingAddress ? user.mailingAddress.streetTwo : "",
            mailingZipcode: user.mailingAddress ? user.mailingAddress.zipcode : "",
            userType: user.userType,
            id: user.id,
            idProofType: user.idProofType
        }
        if (user.primaryAddress) {
            // this.setState({ disableCheckBox: false })
            if (Strings.States[user.primaryAddress.country]) {
                if (this.formRef.current) {
                    this.formRef.current.setFieldsValue({ "primaryCountry": user.primaryAddress.country })
                }
                this.setState({ showPrimarySelect: true })

            } else {
                this.setState({ showPrimarySelect: false })
            }

        }
        if (user.mailingAddress) {
            if (Strings.States[user.mailingAddress.country]) {
                if (this.formRef.current) {
                    this.formRef.current.setFieldsValue({ "mailingCountry": user.mailingAddress.country })
                }
                this.setState({ showMailingSelect: true })
            } else {
                this.setState({ showMailingSelect: false })
            }

        }
        this.setState({
            checkBoxStatus: user.mailingAddressIsDifferAsPrimary ? user.mailingAddressIsDifferAsPrimary : false,
            mailingFormDisable: user.mailingAddressIsDifferAsPrimary ? false : true,
            user: obj
        })
    }

    // upload formw9 file
    onUploadW9 = (info) => {
        console.log(this.formRef1.current.getFieldValue("taxIdentificationType"));
        this.setState({ loading: true });
        const form = new FormData();
        form.append('file', info);
        this.props.uploadSecureFiles(form).then(res => {
            if (res.type === Strings.successType) {
                this.setState({
                    taxIdentificationForm: res.data
                });
                this.showingMessage(Strings.successType, res.message, Strings.profileTitle);
                this.setState({ loading: false });
            } else {
                this.showingMessage(Strings.errorType, res.message, Strings.profileTitle)
                this.setState({ loading: false });
            }
        })
    }

    // remove w9form
    removeW9Form() {
        this.setState({
            taxIdentificationForm: null
        });
    }

    // getSignedUrl
    getSignedUrl(val) {
        console.log(val)
        this.setState({ loading: true });
        this.props.getSignedUrls({
            key: val,
            from: "user"
        }).then((res) => {
            if (res.type === Strings.successType) {
                this.setState({ loading: false });
                window.open(res.data, '_blank')
            } else {
                this.setState({ loading: false });
            }
        })
    }

    //showing notification message
    showingMessage(type, message, title) {
        notification[type]({
            description: message,
        });
    }

    //handling form submit
    handleSubmit(values) {
        this.setState({ loading: true });
        let user = JSON.parse(localStorage.getItem('userData'));
        let obj = {
        }
        if (this.state.taxIdentificationForm) {
            obj.taxIdentificationType = values.taxIdentificationType;
            obj.taxIdentificationForm = this.state.taxIdentificationForm;
        }
        this.props.updateProfile({ userId: user.id, data: obj }).then(res => {
            if (res.type === Strings.successType) {
                this.showingMessage(Strings.successType, res.message, Strings.profileTitle);
                this.bindValues(res.data);
                this.props.history.push('/profile');
                this.setState({
                    loading: false,
                    actualUser: res.data
                });
            } else {
                this.showingMessage(Strings.errorType, res.message, Strings.profileTitle)
                this.setState({ loading: false });
            }
        })
    }

    updateW9Status() {
        this.setState({ loading: true });
        let user = JSON.parse(localStorage.getItem('userData'));
        let obj = {
            isW9FormFilledUp: true
        }
        this.props.updateProfile({ userId: user.id, data: obj }).then(res => {
            if (res.type === Strings.successType) {
                this.showingMessage(Strings.successType, res.message, Strings.profileTitle);
                this.setState({
                    loading: false,
                    actualUser: res.data,
                    isShowModal: false
                });
            } else {
                this.showingMessage(Strings.errorType, res.message, Strings.profileTitle)
                this.setState({ loading: false });
            }
        })
    }

    handleDocuments(val) {
        this.setState({ loading: true });
        this.props.getSignedUrls({
            key: val,
            from: "documents"
        }).then((res) => {
            if (res.type === Strings.successType) {
                this.setState({ loading: false });
                window.open(res.data, '_blank')
            } else {
                this.setState({ loading: false });
            }
        })
    }


    render() {

        return (

            <div className="profile-main" key={this.state.user ? this.state.user : null} id="profile-main-section">
                {this.state.loading ? <Loading></Loading> : null}
                <div className="header-main" key={this.state.user ? this.state.user : null}>
                    <AppHeader history={this.props.history} user={this.state.actualUser} />
                </div>
                <div className="container">
                    <h1 className="login-register-title-h profile-title-h">Hello <span className="">{this.state.user ? (this.state.user.firstName + " " + this.state.user.lastName) : null}</span><br />Make The Right Investment</h1>

                    <Breadcrumb separator=">">
                        <Breadcrumb.Item >Account  {`/`} </Breadcrumb.Item>
                        {this.state.step === 0 ?
                            <span >Personal Details</span> : null}
                    </Breadcrumb>

                    <h1 className="page-main-heading">Profile </h1>
                    <Row gutter={[16]} className="invest-main-row-sec" justify="center">
                        <Col sm={24} md={14} lg={19}>
                            <div>
                                <div className="document-type-card">
                                    <div className="card-main" id="steps-investor-individual">
                                        <>
                                            <div className="profile-card">
                                                <h1 className="dummy">hai</h1>
                                                {this.state.actualUser && this.state.actualUser.firstName ? <>

                                                    <div className={this.state.actualUser.verifyStatus === 2 ? 'profile-box' : 'profile-unBox'} key={this.state.actualUser ? this.state.actualUser : null}>
                                                        <div className="eidt-section">
                                                            <img src={editIcon} alt={editIcon} onClick={() => {
                                                                this.props.history.push('/edit-profile');
                                                            }} className="edit-icon" />
                                                        </div>
                                                        <div className="basic-sec">
                                                            <div className="investor-name"> {this.state.actualUser.firstName} {this.state.actualUser.middleName} {this.state.actualUser.lastName}</div>

                                                            <div className="investor-email">{this.state.actualUser.email}</div>

                                                            <div>{this.state.actualUser.countryCode + " " + this.state.actualUser.phoneNumber}</div>
                                                        </div>
                                                        <Row gutter={[16]} className="primary-details" justify='center'>
                                                            <Col sm={24} md={12} lg={12}>
                                                                <div className="parimary-address-label"> Address</div>
                                                                {this.state.actualUser && this.state.actualUser.primaryAddress ?
                                                                    <div className="security-section">
                                                                        <label>{this.state.actualUser.primaryAddress.streetOne},
                                                                            {this.state.actualUser.primaryAddress.streetTwo ? this.state.actualUser.primaryAddress.streetTwo + "," : null}</label>

                                                                        <label> {this.state.actualUser.primaryAddress.city}, {this.state.actualUser.primaryAddress.state}, {this.state.actualUser.primaryAddress.country}:{this.state.actualUser.primaryAddress.zipcode}

                                                                        </label>
                                                                    </div> : null}
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </> : null}

                                            </div>
                                        </>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div >
                <Footer history={this.props.history} />
            </div >
        )
    }
}

//in this state we have all data which disptched functions called & mapping to props
const mapStateToProps = state => {
    return {
        user: state.investors.loggedUserDetails ? state.investors.loggedUserDetails : {},
    }
};

//exporting profile page
export default connect(mapStateToProps, { updateProfile, getUserData, uploadImage, uploadSecureFiles, getSignedUrls, getUserDataProfile })(Profile);
